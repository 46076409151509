var sliderApp = angular.module('sliderApp', ['ngAnimate']);

sliderApp.directive('slider', function($timeout) {
    return {
        restrict: 'A',
        link: function(scope, elem, attrs) {

            scope.currentScreen = 'home2';
            scope.home2 = true;

            scope.next = function() {
                if (scope.currentScreen === 'home1') {
                    scope.currentScreen = 'home2';
                } else {
                    scope.currentScreen = 'home1';
                }

            };

            scope.$watch('currentScreen', function() {
                if (scope.currentScreen === 'home1') {
                   scope.home2 = false;
                   scope.home1 = true;
                } else {
                   scope.home1 = false;
                   scope.home2 = true;
                }
            });

            var timer;
            var sliderFunc = function() {
                timer = $timeout(function() {
                    scope.next();
                    timer = $timeout(sliderFunc, 5000);
                }, 5000);
            };

            sliderFunc();

            scope.$on('$destroy', function() {
                $timeout.cancel(timer); // when the scope is getting destroyed, cancel the timer
            });
        }
    };
});