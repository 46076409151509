angular.module('datepickerLocaldateApp', [])
    .directive('datepickerlocaldate', ['$parse', function ($parse) {
        return {
            restrict: 'A',
            require: ['ngModel'],
            link: function (scope, element, attrs, ctrls) {
                var ngModelController = ctrls[0];
                // called with a JavaScript Date object when picked from the datepicker
                ngModelController.$parsers.push(function (viewValue) {
                    // add timezone shift to the date is was not added yet
                    if (viewValue !== undefined && viewValue !== null) {
                        viewValue.setMinutes(viewValue.getMinutes() - viewValue.getTimezoneOffset());
                    }
                    return viewValue;
                });
            }
        };
    }]);
