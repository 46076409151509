const publicModule = angular.module('public', [
    'ui.checkbox',
    'ngAnimate',
    "ui.bootstrap" /* unfolding menu */,
    "cgBusy",
    "ngSanitize",
    //"common.directives.vticker",
    "sliderApp",
    "scrollOnClickApp",
    "common.directives.disableOnPromise",
    "common.filters.urlEncode",
    'angular-click-outside',
]);

angular.module('public').value('cgBusyDefaults',{
    message:'',
    // minDuration: 700
});


publicModule.config(['$httpProvider', function ($httpProvider) {
    // do not cache $http gets in general
    // initialize get if needed
    if (!$httpProvider.defaults.headers.get) {
        $httpProvider.defaults.headers.get = {};
    }
    // disable IE ajax request caching
    /*jshint -W069 */
    $httpProvider.defaults.headers.get['If-Modified-Since'] = 'Sat, 26 Jul 1997 05:00:00 GMT';
    // extra
    $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
    $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';
}]);

/**
 * Error handler
 */
publicModule.factory('$exceptionHandler', ['$log', '$injector', function($log, $injector) {
    return function myExceptionHandler(exception, cause) {
        var $http = $injector.get("$http");
        var $location = $injector.get("$location");

        var message = "Exception from frontend (app), URL: " + $location.path();
        if (angular.isDefined(exception.message) && exception.message !== null) {
            if (exception.message.indexOf("Unauthorized") > -1) {
                message += ", Error: 401 Unauthorized";
                $http.post('/api/logging/log.json', {level: 'WARNING', message: message});
                return;
            }
            message += ", " + exception.message;
        }
        if (angular.isDefined(exception.stack) && exception.stack !== null) {
            message += "\nTrace: " + exception.stack + "\n";
        }
        $http.post('/api/logging/log.json', {level: 'ERROR', message: message});
        $log.error(exception, cause);
    };
}]);
