angular.module("common.directives.disableOnPromise", []).directive('disableOnPromise', function ($parse) {
    return {
        restrict: 'A',

        scope:{
            disableOnPromise:'='
        },

        link: function (scope, element, attrs) {
            try {
                if(attrs.hasOwnProperty("ngClick")) {
                    element.on('click', function (event) {
                        if (angular.isDefined(scope.disableOnPromise) && scope.disableOnPromise !== null) {
                            attrs.$set('disabled', true);
                            scope.disableOnPromise.then(
                                function () {
                                    attrs.$set('disabled', false);
                                },
                                function () {
                                    attrs.$set('disabled', false);
                                }
                            );
                        }
                    });
                }
                else {
                    scope.$watchCollection('disableOnPromise', function (newPromise) {
                        if (angular.isDefined(newPromise) && newPromise !== null) {
                            attrs.$set('disabled', true);
                            newPromise.then(
                                function () {
                                    attrs.$set('disabled', false);
                                },
                                function () {
                                    attrs.$set('disabled', false);
                                }
                            );
                        }
                    });
                }
            } catch (e) {
                console.log("disableOnPromise: exception caught");
            }
        }
    };
});
