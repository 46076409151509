/**
 * Search button functionality on HP.
 * No dependency on any JS library.
 */
const HP_SEARCH_FORM_NAME = "hp_search_form";
const HP_SEARCH_FIELD_NAME = "hp_search_field";

function processSearchData(searchContext) {
    let form = document.getElementById(HP_SEARCH_FORM_NAME);
    let data = document.getElementById(HP_SEARCH_FIELD_NAME).value;
    let skills = data.split(",");
    let searchFieldName = "skills" + (searchContext == "resources" ? "[]" : "");

    for (let i = 0; i < skills.length; i++) {
        let hiddenInput = document.createElement('input');
        hiddenInput.type = 'hidden';
        hiddenInput.name = searchFieldName;
        hiddenInput.value = (skills[i]).trim();
        form.appendChild(hiddenInput);
    }
    document.getElementById(HP_SEARCH_FIELD_NAME).value = "";
    form.submit();
}
function onSearchButtonPressed(searchContext) {
    processSearchData(searchContext);
    return false;
}
function onFormSubmit(searchContext) {
    processSearchData(searchContext);
    return false;
}